import React, { useEffect, useState } from 'react';
import { useAuth } from '@common/auth/use-auth';
import { AccountSettingsPanel } from '@common/auth/ui/account-settings/account-settings-panel';
import { Button } from '@ui/buttons/button';
import { Trans } from '@ui/i18n/trans';
import { useForm } from 'react-hook-form';

interface Subscription {
  id: number;
  user_id: number;
  price_id: number;
  gateway_name: string;
  gateway_id: string;
  gateway_status: string;
  quantity: number;
  description: string;
  trial_ends_at: string | null;
  ends_at: string | null;
  renews_at: string | null;
  created_at: string;
  updated_at: string;
  product_id: number;
}

interface EmailData {
  email1: string;
  email2: string;
  email3: string;
}

interface ChildAccount {
  email_1: string;
  email_2: string;
  email_3: string;
}

type EmailField = 'email1' | 'email2' | 'email3';
type ChildAccountField = 'email_1' | 'email_2' | 'email_3';

export const AccountPlanFamilyPanel: React.FC = () => {
  const { user } = useAuth();
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [childAccounts, setChildAccounts] = useState<ChildAccount>({
    email_1: '',
    email_2: '',
    email_3: ''
  });
  const [editingField, setEditingField] = useState<EmailField | null>(null);
  const { register, handleSubmit, setValue, watch } = useForm<EmailData>();
  const [loading, setLoading] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState<string | null>(null);
  const [isBlocked, setIsBlocked] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!user) return;

      try {
        const [subResponse, childResponse] = await Promise.all([
          fetch(`/update-user-roles.php?user_id=${user.id}`),
          fetch(`/get-child-accounts.php?user_id=${user.id}&user_email=${encodeURIComponent(user.email)}`)
        ]);

        const subData = await subResponse.json();
        if (subResponse.ok) {
          setSubscriptions(subData);
        }

        const childData = await childResponse.json();
        switch(childData.status) {
          case 'blocked':
            setIsBlocked(true);
            setFeedbackMessage(childData.message);
            break;
          case 'success':
            setChildAccounts(childData.accounts);
            setValue('email1', childData.accounts.email_1 || '');
            setValue('email2', childData.accounts.email_2 || '');
            setValue('email3', childData.accounts.email_3 || '');
            break;
        }
      } catch (error) {
        setFeedbackMessage('Erro ao carregar dados.');
      }
    };

    fetchData();
  }, [user, setValue]);

  const handleEdit = (field: EmailField) => {
    setEditingField(field);
    const childAccountField = getChildAccountField(field);
    setValue(field, childAccounts[childAccountField] || '');
  };

  const getChildAccountField = (field: EmailField): ChildAccountField => {
    const mapping: Record<EmailField, ChildAccountField> = {
      'email1': 'email_1',
      'email2': 'email_2',
      'email3': 'email_3'
    };
    return mapping[field];
  };

  const onSubmit = async (data: EmailData) => {
    if (!user) {
     // console.error('O usuário não está autenticado.');
      return;
    }

    // Encontrar qual campo está sendo salvo
    const targetField = editingField || emailFields.find(field => {
      const childAccountField = getChildAccountField(field);
      return !childAccounts[childAccountField] && data[field];
    });

    if (!targetField) {
      setFeedbackMessage('Por favor, preencha pelo menos um campo de email.');
      return;
    }

    setLoading(true);
    setFeedbackMessage(null);

    try {
      const emails = {
        email1: data.email1,
        email2: data.email2,
        email3: data.email3,
        editingField: targetField
      };

      const response = await fetch('/save-emails.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user.id,
          emails: emails,
          subscriptionId: subscriptions[0]?.id
        }),
      });
      
      const result = await response.json();

      if (response.ok) {
        setFeedbackMessage('Registros salvos com sucesso!');
        const childAccountField = getChildAccountField(targetField);
        setChildAccounts(prev => ({
          ...prev,
          [childAccountField]: data[targetField]
        }));
        setEditingField(null);
      } else {
        setFeedbackMessage(result.message || 'Erro ao salvar registros.');
      }
    } catch (error) {
     // console.error('Erro ao salvar registros:', error);
      setFeedbackMessage('Erro inesperado. Tente novamente mais tarde.');
    } finally {
      setLoading(false);
    }
  };

  const watchEmails = watch(['email1', 'email2', 'email3']);
  const emailFields: EmailField[] = ['email1', 'email2', 'email3'];

  const hasActiveSubscription = subscriptions.length > 0 && 
    subscriptions.some(sub => sub.gateway_status === 'active' && sub.product_id === 3);

  if (!hasActiveSubscription) {
    return (
      <AccountSettingsPanel
        id="account-plan-family"
        title={<Trans message="Plano Membro da Família" />}
      >
        <p>Você não tem uma assinatura Família ativa para esta ação.</p>
      </AccountSettingsPanel>
    );
  }

  if (isBlocked) {
    return (
      <AccountSettingsPanel
        id="account-plan-family"
        title={<Trans message="Plano Membro da Família" />}
      >
        <p>{feedbackMessage}</p>
      </AccountSettingsPanel>
    );
  }

  return (
    <AccountSettingsPanel
      id="account-plan-family"
      title={<Trans message="Plano Membros da Família" />}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        {emailFields.map((field, index) => {
          const childAccountField = getChildAccountField(field);
          const hasExistingEmail = Boolean(childAccounts[childAccountField]);
          const isEditing = editingField === field;

          return (
            <div key={field} className="flex items-center space-x-2">
              <input
                {...register(field)}
                type="email"
                placeholder={`Email ${index + 1}`}
                disabled={!isEditing && hasExistingEmail}
                className="flex-1 p-2 border rounded"
              />
              {hasExistingEmail && !isEditing && (
                <Button 
                  type="button" 
                  onClick={() => handleEdit(field)}
                  variant="outline"
                >
                  <Trans message="Editar" />
                </Button>
              )}
              {(isEditing || (!hasExistingEmail && watchEmails[index])) && (
                <Button 
                  type="submit" 
                  disabled={loading}
                  variant="flat"
                  onClick={() => !editingField && setEditingField(field)}
                >
                  <Trans message={loading ? "Salvando..." : "Salvar"} />
                </Button>
              )}
            </div>
          );
        })}
        {feedbackMessage && (
          <div className={`mt-2 p-2 rounded ${
            feedbackMessage.includes('Erro') ? 'bg-red-100' : 'bg-green-100'
          }`}>
            {feedbackMessage}
          </div>
        )}
      </form>
    </AccountSettingsPanel>
  );
};